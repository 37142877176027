import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Container,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
} from 'components/apollo';
import {
  Formik,
  ErrorMessage,
} from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import FieldSet from 'components/inputs/field-set/field-set';
import PhoneInput from 'components/inputs/phone-input';
import { Row, Column } from 'components/grid';
import { InvitationTermsOfUseModal } from '../terms-of-use/terms-of-use';
import './create-guest-account.css';

const propTypes = {
  customerEmail: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invitationId: PropTypes.string
    })
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(50, 'Maximum ${max} characters') // eslint-disable-line no-template-curly-in-string
    .required('Required'),
  lastName: yup
    .string()
    .max(50, 'Maximum ${max} characters') // eslint-disable-line no-template-curly-in-string
    .required('Required'),
  companyName: yup
    .string()
    .max(50, 'Maximum ${max} characters') // eslint-disable-line no-template-curly-in-string
    .required('Required'),
  phoneNumber: yup
    .string()
    .required('Required'),
  termsOfUse: yup
    .boolean()
    .oneOf([true], 'Required')
});

const BASE_URL = process.env.REACT_APP_ANDROMEDA_GUEST_BASEURL;

const getHeadersForAxios = async () => {
  return {
    headers: {
      'api-key': '214c9c31-9c0b-4133-9045-fc5450e0ea05',
      'Content-Type': 'application/json; charset=utf-8'
    },
  };
};

const CreateGuestAccount = ({
  customerEmail,
  history,
  match: { params: { invitationId } },
  hideModal,
  showModal
}) => {
  const initialValues = {
    email: customerEmail,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyName: '',
    termsOfUse: false
  };
  const [error, setError] = useState('');

  const handleUpdateInvitationsResponse = (response, values, actions) => {
    actions?.setSubmitting(true);

    const message = () => response === 'reject'
      ? (
        <div>
          <p>
            If you reject this invitation, this action can not be undone.
            <br></br>For getting access to the project you will need to be invited again.
          </p>
          <p>
            Please confirm that you want to reject this invitation.
          </p>
        </div>
      )
      : (
        <div>
          <p>Please verify the information that you filled in.</p>

          <div>
            <b>Username:</b> {values.email}<br />
            <b>First name:</b> {values.firstName}<br />
            <b>Last name:</b> {values.lastName}<br />
            <b>Company:</b> {values.companyName}<br />
            <b>Phone:</b> {values.phoneNumber}<br /><br />
          </div>

          <p>
            After confirming your information a new account will be created and you will be able to log in to AFRY Portal.
            <br></br>You will need to accept Microsoft terms and conditions during the first log in.
          </p>
        </div>
      );

      showModal('VALIDATION', {
        title: 'Response to invitation',
        message: message(),
        acceptButtonText: 'Confirm',
        onAccept: async () => {
          axios.put(`${BASE_URL}/external/invitation/response/${response}/${invitationId}`, values, await getHeadersForAxios())
            .then(() => {
              history.go(0);
            })
            .catch(() => {
              setError('An error has occurred');
              hideModal();
            })
            .finally(() => {
              actions?.setSubmitting(false);
            });
        }
      });
  };

  const handleTermsOfUseClick = (e) => {
    e.preventDefault();
    
    showModal('ALERT', {
      title: 'Terms of use',
      message: InvitationTermsOfUseModal()
    });
  };

  const handleFormikSubmit = async (values, actions) => {
    setError('');
    handleUpdateInvitationsResponse('accept', values, actions);
  };

  const renderError = () => error && <div className="error">{error}</div>;

  return (
    <Container className="create-guest-account">
      <h2>Create an account</h2>
      <h4>Your username: {customerEmail}</h4>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleFormikSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          handleBlur,
          values,
        }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Column tablet={6} desktop={6} largeDesktop={4}>
                  <FieldSet
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                  />
                </Column>
                <Column tablet={6} desktop={6} largeDesktop={4}>
                  <FieldSet
                    name="lastName"
                    label="Last name"
                    placeholder="Last name"
                  />
                </Column>
              </Row>

              <Row>
                <Column tablet={6} desktop={6} largeDesktop={4}>
                  <FieldSet
                    name="companyName"
                    label="Company name"
                    placeholder="Company name"
                  />
                </Column>
                <Column tablet={6} desktop={6} largeDesktop={4}>
                  <FormGroup>
                    <Label for="phoneNumber">Phone Number</Label>
                    <PhoneInput
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="+46 70 123 45 67"
                      value={values.phoneNumber}
                      onChange={(value) => { handleChange('phoneNumber')(value || ''); }}
                      onBlur={(e) => { handleBlur('phoneNumber')(e); }}
                      disabled={isSubmitting}
                    />
                    <FormFeedback>
                      <ErrorMessage name="phoneNumber" />
                    </FormFeedback>
                  </FormGroup>
                </Column>
              </Row>

              <FormGroup check>
                <Label for="termsOfUse">
                  <Checkbox
                    id="termsOfUse"
                    name="termsOfUse"
                    onChange={(value) => { handleChange('termsOfUse')(value || false); }}
                  />
                Use of this Website requires that you accept the <a onClick={e => handleTermsOfUseClick(e)} href="\">Terms of Use</a>
                </Label>
                <FormFeedback>
                  <ErrorMessage name="termsOfUse" />
                </FormFeedback>
              </FormGroup>

              {renderError()}

              <div className="footer">
                <Container className="controls">
                  <Button onClick={() => handleUpdateInvitationsResponse('reject')} className="control">Reject invitation(s)</Button>
                  <Button type="submit" className="control" color="primary">Create</Button>
                </Container>
              </div>

            </Form>
          )}
      </Formik>
    </Container>
  );
};

CreateGuestAccount.propTypes = propTypes;

export default CreateGuestAccount;
