import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Container, Loader, Navbar } from 'components/apollo';
import { LayoutContainer } from '../../../../views/shared/layout';
import BrandIcon from 'components/navigation/main-navigation/common/brand-icon/brand-icon';
import InvitationProjectsList from '../../components/invitation-projects-list/invitation-projects-list';
import CreateGuestAccount from '../../components/create-guest-account/create-guest-account-container';
import { NotFoundContainer } from '../../../../views/shared/not-found';
import { InvitationResponseStatus } from '../../../../constants';
import './invitation-to-create-guest-account.css';

const matchPropType = PropTypes.shape({
  params: PropTypes.shape({
    invitationId: PropTypes.string,
  }),
});

const propTypes = {
  match: matchPropType.isRequired,
};

const BASE_URL = process.env.REACT_APP_ANDROMEDA_GUEST_BASEURL;

const respondedStatuses = [ InvitationResponseStatus.Accepted, InvitationResponseStatus.Rejected, InvitationResponseStatus.Joined ];

const getHeadersForAxios = async () => {
  return {
    headers: {
      'api-key': '214c9c31-9c0b-4133-9045-fc5450e0ea05',
      'Content-Type': 'application/json; charset=utf-8'
    },
  };
};

const AllInvitationsRespondedPage = () => (
  <LayoutContainer>
    <Container>
      <h2>You have answered an invitation sent by AFRY</h2>
      <p>
        If you have chosen to accept the invitation, here is what will happen
        now:
      </p>
      <ul>
        <li>
          A new account will be created for you at AFRY. (Please note that it
          can take up to 10 minutes before it is ready to use.)
        </li>
        <li>
          During the first login to AFRY Portal you have to accept the terms and
          conditions from Microsoft.
        </li>
        <li>
          When your account has been created you can find your{' '}
          <a href="https://portal.afry.com/projects">projects here</a>.
        </li>
      </ul>
      <br></br>
      <p>
        In case you want to change your response, please contact the inviting
        project manager.
      </p>
      <p>
        For more information about us please visit our site{' '}
        <a href="https://afry.com">AFRY.com</a>
      </p>
    </Container>
  </LayoutContainer>
);

const AllInvitationsExpiredPage = () => (
  <LayoutContainer>
    <Container>
      <h2>This invitation sent by AFRY has expired</h2>
      <p>
      This invitation to join a project at AFRY has expired. If you still want access 
      to this project, please contact the Project Manager and ask them to resend the invitation.
      </p>
      <p>
        For more information about us please visit our site{' '}
        <a href="https://afry.com">AFRY.com</a>
      </p>
    </Container>
  </LayoutContainer>
);

const InvitationToCreateGuestAccount = ({
  match: {
    params: { invitationId },
  },
}) => {
  const [invitationList, setInvitationList] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [allInvitationsResponded, setAllInvitationsResponded] = useState(false);
  const [allInvitationsExpired, setAllInvitationsExpired] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      axios
        .get(`${BASE_URL}/external/invitation/range/customer/${invitationId}`, await getHeadersForAxios())
        .then((response) => {
          if (response.data) {
            setInvitationList(response.data.pendingSubjects);
            setCustomerEmail(response.data.customerEmail);
            setAllInvitationsResponded(respondedStatuses.includes(response.data.invitationResponseStatus));
            setAllInvitationsExpired(response.data.invitationResponseStatus === InvitationResponseStatus.Expired);
          } else {
            setNotFound(true);
          }
        })
        .catch(() => setNotFound(true))
        .finally(() => setIsLoading(false));
    };

    fetchData();    
  }, [invitationId]);

  return (
    <React.Fragment>
      <Helmet title="AFRY" />
      <Navbar dark fixed="top" expand>
        <Container>
        <BrandIcon homeTarget="" currentLocation="" />
        </Container>
      </Navbar>
      <div className="invitation-to-create-guest-account">
        {notFound && !isLoading && <NotFoundContainer />}
        {allInvitationsResponded && <AllInvitationsRespondedPage />}
        {allInvitationsExpired && <AllInvitationsExpiredPage />}
        {isLoading && <Loader height={100} width={200} />}
        {!notFound &&
          !allInvitationsResponded &&
          !allInvitationsExpired &&
          !isLoading &&
          invitationList && (
            <LayoutContainer>
              <InvitationProjectsList invitations={invitationList} />
              <CreateGuestAccount
                customerEmail={customerEmail}
                totalInvitations={invitationList.length}
              />
            </LayoutContainer>
          )}
      </div>
    </React.Fragment>
  );
};

InvitationToCreateGuestAccount.propTypes = propTypes;

export default InvitationToCreateGuestAccount;
