
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/apollo';
import { Row, Column } from 'components/grid';
import './invitation-projects-list.css';

const propTypes = {
  invitations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const InvitationProjectsList = ({ invitations }) => (
  <div className="invitation-project-list">
    <Container>
      <h2>You have been invited to join following project(s)</h2>

      <Row className="table-header">
        <Column mobile={12} tablet={4} desktop={4} largeDesktop={4}>
          Project
        </Column>
        <Column mobile={12} tablet={3} desktop={3} largeDesktop={2}>
          Project Manager
        </Column>
        <Column mobile={12} tablet={5} desktop={5} largeDesktop={6}>
          Optional message from project manager
        </Column>
      </Row>
      {
        invitations && invitations.map(invitation => (
          <Row key={invitation.subjectKey}>
            <Column mobile={12} tablet={4} desktop={4} largeDesktop={4}>
              {invitation.subjectName}
            </Column>
            <Column mobile={12} tablet={3} desktop={3} largeDesktop={2}>
              {invitation.invitingPerson}
            </Column>
            <Column mobile={12} tablet={5} desktop={5} largeDesktop={6}>
              {invitation.optionalMessage}
            </Column>
          </Row>
        ))
      }
    </Container>
  </div>
);

InvitationProjectsList.propTypes = propTypes;

export default InvitationProjectsList;
